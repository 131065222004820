import React, { useState } from "react";
import "./FaqItem.scss";

function FaqAnswer(props){

  if(typeof props.answer == 'object' ) {
    return (
      <ul className="faqUl">
        { props.answer.map((item, index) => (

            <li className="m-2"
              key={index}
            ><span> {item} </span></li>
        ))}
      </ul> );
  }
  return (<div className="ml-4 mr-4"> {props.answer} </div>);
}
function FaqItem(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <article className="FaqItem py-4" onClick={() => setExpanded(!expanded)}>
      <h4>
        <span className="text-primary mr-3">
          <i
            className={
              "fas" +
              (expanded ? " fa-minus" : "") +
              (!expanded ? " fa-plus" : "")
            }
          ></i>
        </span>
        {props.question}
      </h4>

      {/*expanded && <div className="mt-3">{props.answer}</div>*/}
      {expanded && <div className="mt-3"> <FaqAnswer answer = {props.answer}> </FaqAnswer> </div>}
    </article>
  );
}

export default FaqItem;
