import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";

function FaqSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Faq
          items={[
            {
              question: "How do I use Hello Ele ?",
              answer:
                "First step is to create a group by running '$ ele create-group' and entering the S3 credentials. This will create a group-config.ini file which will be used by other developers to join this group. Join a group by running '$ ele join group-config.ini'. Now you are ready to upload, download files.  ",
            },
            {
              question: "Are my S3 credentials safe?",
              answer:
                "Yes, all your S3 credentials are with you, they are never sent to 'ele' server.Your S3 key,secret settings are stored in group-settings and shared by you with other devs.",
            },            
            {
              question: "What are the available commands?",
              answer:
                "'ele upload *file-path*' - to upload a file, 'ele download *file-number*' - to download a file, 'ele delete *file-number*' - to delete a file, 'ele create-group' - to create a group, 'ele join *group-config.ini*' - to join a group.",
            },
            {
              question: "Does ele work only with AWS S3?",
              answer:
                "No, ele can also work with other S3 API compatable cloud storage providers. As of now DigitalOcean Spaces, Backblaze B2, Google Storage(beta) are tested.",
            },            
          ]}
        ></Faq>
      </Container>
    </Section>
  );
}

export default FaqSection;
