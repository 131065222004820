import React from "react";
import HowToSection from "../components/HowToSection";

function HowToPage(props) {
  return (
    <HowToSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="How To Setup"
      subtitle=""
    ></HowToSection>
  );
}

export default HowToPage;
