import React from "react";
import HeroSection from "./../components/HeroSection";
//import ClientsSection from "./../components/ClientsSection";
import FeaturesSection from "./../components/FeaturesSection";
//import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();
//https://uploads.divjoy.com/undraw-japan_ubgk.svg
  return (
    <>
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Simple file transfer tool for devs"
        subtitle="Upload, Download files using file-indexes from command line."
        buttonText="Get Started"
        buttonColor="primary"
        image="undraw_shared_workspace_hwky.svg"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/howto");
        }}
      ></HeroSection>
      {/*<ClientsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      ></ClientsSection> */}
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="No more sharing long urls, just use file-numbers. You can upload a file and you'll recieve a file-number in return. You can use this file-number to download it from other computers."
      ></FeaturesSection>
      { /* <TestimonialsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      ></TestimonialsSection> */ }
      <NewsletterSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in touch"
        subtitle="Follow me on twitter"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      ></NewsletterSection> 
    </>
  );
}

export default IndexPage;
