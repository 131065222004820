import React from "react";
import "./SectionHeader.scss";

// Supports Paras with <br/>
function SubTitle(props){
  let DIVIDER = "|||";
  let mapsOfDesc = props.subtitle.split(DIVIDER);
//      return <div> {item} </div>;
  let res = mapsOfDesc.map(function(item){
      return item;
    });

  let extraClass = (props.textCentered) ? " w-100 text-center  " :"";
  return(<p className= { "SectionHeader__subtitle mb-0" +extraClass} >
    {
      res
    }
  </p>);
}

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }
  let extraClass = (props.textCentered) ? " text-center " :"";

  return (
    <header
      className={
        "SectionHeader" + (props.className ? ` ${props.className}` : "")
      }
    >
      {props.title && (
        <h1
          className={
            "font-weight-bold" +
            (props.subtitle && props.spaced ? " mb-4" : "") +
            (!props.subtitle ? " mb-0" : "") +
            (props.size ? ` h${props.size}` : "") + extraClass
          }
        >
          {props.title} {
            props.textEndTwitterIcon && (
                      <a href="https://twitter.com/prakis" target="_blank" rel="noopener noreferrer">
                        <span className="icon">
                          <i className="fab fa-twitter"></i>
                        </span>
                      </a> )
          }
        </h1>
      )}
      {props.subtitle && (
        <SubTitle subtitle={props.subtitle} textCentered={props.textCentered} />
      )}
    </header>
  );
}

export default SectionHeader;
