import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";


function Credentials(props){

  if(typeof props.creds == 'object' ) {
    return (
      <ul className="faqUl list-group">
        { props.creds.map((item, index) => (

            <li className="howToLi list-group-item" key={index}
            ><span className="font-weight-normal"> {item} </span></li>
        ))}
      </ul> );
  }
  return (<div className="ml-4 mr-4"> {props.creds} </div>);
}

function Cmd(props){
  return(<div className="shell-wrap">
          <p className="shell-top-bar">{props.title}</p>
          <ul className="shell-body">
            <li>{props.text}</li>
          </ul>
        </div>);
}

function HowToSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>

        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
          isForHowToPage="true"
        ></SectionHeader>



        <div className="HowToContainer">

        <SectionHeader
            title={"Step-by-step Guide"}
            subtitle={"The following article will helps you setup ele with BackBlaze"}
            spaced={true}
            size={3}
            className="text-center text-lg-left"
          ></SectionHeader> 
            <div className="ml-4 mb-4">
            <a target="_blank" href="https://dev.to/prakis/using-ele-with-free-backblaze-bucket-lig" > Step by step guide to setup ele with BackBlaze</a>
            </div>

        <SectionHeader
            title={"Installation"}
            subtitle={"You need node npm to install ele"}
            spaced={true}
            size={3}
            className="text-center text-lg-left"
          ></SectionHeader> 
            <div className="ml-4 mb-4">
                <Cmd title="To install ele" text="sudo npm install -g helloele" />
              { /*<ul className="faqUl list-group">
                <li className="howToLi list-group-item" key={1}><span > Linux </span></li>
                <li className="howToLi list-group-item" key={2}><span > Windows </span></li>
                <li className="howToLi list-group-item" key={3}><span > Mac OS </span></li>            
              </ul> */}
            </div>

          <SectionHeader
            title={"How to use ele?"}
            subtitle={"Ele works with groups. Anyone with S3 credentials can create a group and share the group-credentials 'GROUP-NAME.ini' file with other group members.  Group members who recieved 'GROUP-NAME.ini' file can join this group. Now all group members can upload and download files using file-number."}
            spaced={true}
            size={3}
            className="text-center text-lg-left"
            isForHowToPage="true"
          ></SectionHeader>        

            <div className="m-4 mb-4">
              <ul className="faqUl list-group">
                <li className="howToLi2 list-group-item" key={1}><div className="font-weight-normal"> Group-Admin with S3 credentials will create a group by running <br/>{<Cmd title="To Create a Group" text="ele create-group " />} <br/> This command will create a 'GROUP-NAME.ini' file. </div></li>
                <li className="howToLi2 list-group-item" key={2}><div className="font-weight-normal"> Group-Admin shares 'GROUP-NAME.ini' file with other group members by email / other means.  </div></li>
                <li className="howToLi2 list-group-item" key={3}><div className="font-weight-normal"> Now group members join this group by running<br/> <Cmd title="To Join a Group" text="ele join GROUP-NAME.ini " /></div></li>                                
              </ul>
            </div>

        { /*
          <SectionHeader
            title={"Group Creation"}
            subtitle={"Admin needs to create a bucket in S3 compatible storage. Then admin can create a group by running $ 'ele create-group'. You require the following bucket credententials to create a group."}
            spaced={true}
            size={3}
            className="text-center text-lg-left"
            isForHowToPage="true"
          ></SectionHeader>        

          { /*<div className="m-3 mb-4 "><a href="https://helloele.com/sample-creds.txt" rel="noopener noreferrer" target="_blank"><h3>Sample Credentials</h3></a></div > */ }

          <Credentials creds={[ "BUCKET NAME", "BUCKET ACCESS KEY ", "BUCKET SECRET KEY ", "S3 SERVER ENDPOINT (please check the following section for default S3 endpoints)",
                    "If you want to publish to a public folder so anyone can access yoru files(outside of your group), give that folder a name ex:public"]}
            ></Credentials>
          <SectionHeader
            title={"s3_server_endpoint :"}
            subtitle={"This url changes based on your S3 storage. Currently the following are tested "}
            spaced={true}
            size={5}
            className="m-4 text-center text-lg-left"
          ></SectionHeader>      
            <div className="ml-4 mr-4">
              <Credentials creds={[ "For AWS S3 Storage = s3.amazonaws.com","For Digital Ocean Spaces = sfo2.digitaloceanspaces.com", " For Backblaze b2 = s3.us-west-002.backblazeb2.com",
                        ]}
                ></Credentials>
            </div>  */ }

        </div>

        <Faq
          items={[
            {
              question: "How do I use Hello Ele ?",
              answer:
                "First step is to create a group by running '$ ele create-group' and entering the S3 credentials. This will create a 'group-name-config.ini' file which will be used by other developers to join this group. Join a group by running '$ ele join group-config.ini'. Now you are ready to upload, download files.  ",
            },
            {
              question: "Are my S3 credentials safe?",
              answer:
                "Yes, all your S3 credentials are with you, they are never sent to 'ele' server.Your S3 key,secret settings are stored in group-settings and shared by you with other devs.",
            },            
            {
              question: "What are the available commands?",
              answer:
                [
                  "$ ele create-group --credentials <creds-file>   # to create a group,",
                  "$ ele join <group-config.ini>  # to join a group.",
                  "$ ele upload <file-path>  # to upload a file",
                  "$ ele download <file-number> # to download a file,", 
                  "$ ele delete <file-number> # to delete a file",
              ]
            },
            {
              question: "Does ele work with Backblaze, DigitalOceans, etc ?",
              answer:
                "Yes, ele can work with any S3 API compatable cloud storage providers. As of now DigitalOcean Spaces, Backblaze B2 are tested.",
            },         
            
          ]}
        ></Faq>
      </Container>
    </Section>
  );
}

/*
            {
              question: "Why is group credentials file encrypted before sharing with group members?",
              answer:
                "You want to add a member to the group but you don't want to share the S3 credentials in a readable text format.",
            },    
*/

//                "'ele upload *file-path*' - to upload a file, 'ele download *file-number*' - to download a file, 'ele delete *file-number*' - to delete a file, 'ele create-group' - to create a group, 'ele join *group-config.ini*' - to join a group.",
export default HowToSection;
