import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// Make sure it hasn't already been initialized
/*if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  });
}*/
/*
const firebaseConfig = {
  apiKey: "AIzaSyDQhgAnosvxj6lkMfhR-58btEkr5fV2Wz0",
  authDomain: "helloele.firebaseapp.com",
  databaseURL: "https://helloele.firebaseio.com",
  projectId: "helloele",
  storageBucket: "helloele.appspot.com",
  messagingSenderId: "372982168912",
  appId: "1:372982168912:web:64bb2af6260397ff110055",
  measurementId: "G-9V26CKJ1N2"
};
*/
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyDQhgAnosvxj6lkMfhR-58btEkr5fV2Wz0",
    authDomain: "helloele.firebaseapp.com",
    databaseURL: "https://helloele.firebaseio.com",
    projectId: "helloele",
    storageBucket: "helloele.appspot.com",
    messagingSenderId: "372982168912",
    appId: "1:372982168912:web:64bb2af6260397ff110055",
    measurementId: "G-9V26CKJ1N2"
  });
}

export default firebase;
