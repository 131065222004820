import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Features from "./Features";

function FeaturesSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Features
          items={[
            {
              title: "File Upload",
              description:
                [ "Upload a file by simply running the command", "$ ele upload abc.zip", "This returns a file-number ex: 124"],
              image: "upload.svg",
            },
            {
              title: "File Download",
              description:
                ["To download on any other computer simply run", "$ ele download 124"],
              image:
                "download.svg",
            },
            {
              title: "Use your own Cloud Storage",
              description:
                "Connect to your own S3 compatable storage. AWS S3, DigitalOcean Spaces, Backblaze B2 Storage are supported",
              image: "undraw_cloud_connect.svg",
            },
            {
              title: "Fully Secure",
              description:
                "Zero-knowledge Access: Your credentials are never sent to HelloEle servers. They stay in your computers.",
              image: "undraw_security_o890.svg",
            },
          ]}
        ></Features>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
