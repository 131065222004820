import React from "react";
import "./SectionHeader.scss";

function SubTitle(props){

  if(typeof props.subtitle == 'object' ) {
    let mapsOfDesc = props.subtitle;
      let res = mapsOfDesc.map(function(item, index){
          return (<li key={index}>{ item } </li>);
        });
      return(<div className= { props.shClassName + "__subtitle mb-0" } >
        <ul>
        {
          res
        }
        </ul>
      </div>);
  }
  return ( <p className= { props.shClassName + "__subtitle mb-0" } > {props.subtitle} </p>);
}

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }


  let shClassName = "SectionHeader";
  if(props.isForHowToPage){
    shClassName = "SectionHeaderHowTo";
  }
  let shClassFullName = shClassName + (props.className ? ` ${props.className}` : "");

  return (
    <header
      className={ shClassFullName }
    >
      {props.title && (
        <h1
          className={
            "font-weight-bold" +
            (props.subtitle && props.spaced ? " mb-4" : "") +
            (!props.subtitle ? " mb-0" : "") +
            (props.size ? ` h${props.size}` : "") 
          }
        >
          {props.title} 
        </h1>
      )}
      {props.subtitle && (
        <SubTitle subtitle={props.subtitle} shClassName={shClassName} textCentered={props.textCentered} />
      )}
    </header>
  );
}

export default SectionHeader;
